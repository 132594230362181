import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://player.vimeo.com/video/424837219"
    bibleGroupSrc="https://www.docdroid.net/JNYrZID/bible-group-homework-5-31-pdf"
    bibleGroupText="Bible Group Homework"
  >
    <SEO title="The Supremacy of Christ - Colossians" />
  </Layout>
)

export default SermonPost
